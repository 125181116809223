import axios from 'axios';
import { REACT_APP_GPT_API_KEY } from '../../api';

export const apiHeaders = {
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${REACT_APP_GPT_API_KEY}`,
  'OpenAI-Beta': 'assistants=v2'
};

export const checkRunStatus = async (threadId, runId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/runs/${runId}`, { headers: apiHeaders });
    return res.data;
  } catch (error) {
    console.error('Error checking run status:', error);
    throw error;
  }
};

export const listMessages = async (threadId) => {
  try {
    const res = await axios.get(`https://api.openai.com/v1/threads/${threadId}/messages`, { headers: apiHeaders });
    return res.data;
  } catch (error) {
    console.error('Error listing messages:', error);
    throw error;
  }
};
