import { directusAPI } from '../api';

// Add fallback and logging for missing key
const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
if (!stripeKey) {
  console.error('REACT_APP_STRIPE_PUBLIC_KEY is not defined. Stripe functionality will not work.');
}

export const stripeService = {
  // Initialize checkout session
  async createCheckoutSession(productId, userId) {
    try {
      const response = await directusAPI.post('/stripe/create-checkout-session', {
        productId,
        userId,
        successUrl: `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}`,
        cancelUrl: `${window.location.origin}/pricing`,
      });
      return response.data;
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  },
  
  // Redirect to checkout using window.location (no Stripe.js needed)
  async redirectToCheckout(sessionId) {
    try {
      // Get checkout URL from backend
      const response = await directusAPI.get(`/stripe/checkout-url/${sessionId}`);
      
      if (response.data && response.data.url) {
        // Redirect directly in the browser
        window.location.href = response.data.url;
        return { success: true };
      } else {
        throw new Error('No checkout URL returned from the server');
      }
    } catch (error) {
      console.error('Error redirecting to checkout:', error);
      throw error;
    }
  },
  
  // Get subscription details
  async getSubscriptionDetails(userId) {
    try {
      const response = await directusAPI.get(`/stripe/subscription/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching subscription details:', error);
      throw error;
    }
  },
  
  // Nowa funkcja do weryfikacji aktywności subskrypcji
  async isSubscriptionActive(userId) {
    try {
      const response = await this.getSubscriptionDetails(userId);
      return response && 
             (response.subscription_status === 'active' || 
              response.subscription_status === 'trialing');
    } catch (error) {
      console.error('Error checking subscription status:', error);
      return false; // W przypadku błędu, przyjmujemy, że subskrypcja nie jest aktywna
    }
  },
  
  // Cancel subscription
  async cancelSubscription(subscriptionId) {
    try {
      const response = await directusAPI.post('/stripe/cancel-subscription', {
        subscriptionId
      });
      return response.data;
    } catch (error) {
      console.error('Error canceling subscription:', error);
      throw error;
    }
  },

  // Dodaj tę nową funkcję do pobierania produktów
  async getProducts() {
    try {
      const response = await directusAPI.get('/stripe/products');
      return response.data;
    } catch (error) {
      console.error('Error fetching products from Stripe:', error);
      throw error;
    }
  },

  // Add this method to your stripeService
  async handleCheckoutSuccess(sessionId) {
    try {
      // Get session details from your backend
      const response = await directusAPI.get(`/stripe/checkout-success/${sessionId}`);
      
      // Return subscription details
      return response.data;
    } catch (error) {
      console.error('Error handling checkout success:', error);
      throw error;
    }
  }
}; 