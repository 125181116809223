import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HomeIcon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/20/solid';
import axios from 'axios';

const isNumeric = (value) => {
  return /^\d+$/.test(value);
};

const fetchNameById = async (slug, type, setBreadcrumbNames) => {
  try {
    const response = await axios.get(`https://panel.prosecaptain.com/items/${type}`, {
      params: {
        filter: { slug: { _eq: slug } }
      }
    });
    if (response.data.data && response.data.data.length > 0) {
      setBreadcrumbNames(prev => ({ ...prev, [slug]: response.data.data[0].title }));
    }
  } catch (error) {
    console.error('Failed to fetch name:', error);
  }
};

export default function DynamicBreadcrumbs() {
  const location = useLocation();
  const navigate = useNavigate();
  const [breadcrumbNames, setBreadcrumbNames] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const pathnames = location.pathname.split('/').filter(x => x);
    let fetchPending = false;

    pathnames.forEach(async (segment, index) => {
      if (isNumeric(segment) && index > 0) { // Assuming the type is just before the ID
        const type = pathnames[index - 1];
        if (['personas', 'projects', 'userjourneys'].includes(type)) {
          if (!breadcrumbNames[segment]) { // Fetch if not already fetched
            fetchPending = true;
            await fetchNameById(segment, type, setBreadcrumbNames);
          }
        }
      }
    });

    if (!fetchPending) {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (Object.keys(breadcrumbNames).length) {
      setLoading(false);
    }
  }, [breadcrumbNames]);

  const generateBreadcrumbName = (segment) => {
    if (breadcrumbNames[segment]) {
      return breadcrumbNames[segment];
    }
    return segment.replace(/-/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
  };

  const pathnames = location.pathname.split('/').filter(x => x);
  if (location.pathname === '/' || pathnames.length === 1) {
    return null;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <nav aria-label="Breadcrumb" className="px-4 sm:px-6 lg:pl-24">
      <div className="sm:hidden py-3">
        <button
          onClick={handleBack}
          className="flex items-center text-gray-500 hover:text-gray-700"
        >
          <ChevronLeftIcon className="h-5 w-5 mr-1" aria-hidden="true" />
          <span className="text-sm font-medium">Back</span>
        </button>
      </div>

      <ol className="hidden sm:flex items-center space-x-4 py-4 overflow-x-auto whitespace-nowrap">
        {pathnames.length > 2 && (
          <li>
            <Link to="/" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </Link>
          </li>
        )}
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          
          return (
            <li key={to} className="flex items-center min-w-fit">
              {index > 0 && (
                <ChevronRightIcon 
                  className="h-5 w-5 flex-shrink-0 text-gray-400" 
                  aria-hidden="true" 
                />
              )}
              {last ? (
                <span 
                  className={`${index > 0 ? 'ml-4' : ''} text-sm font-medium text-gray-500 hover:text-gray-700`}
                  aria-current="page"
                >
                  {generateBreadcrumbName(value)}
                </span>
              ) : (
                <Link 
                  to={to} 
                  className={`${index > 0 ? 'ml-4' : ''} text-sm font-medium text-gray-500 hover:text-gray-700`}
                >
                  {generateBreadcrumbName(value)}
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
