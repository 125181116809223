import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SuggestedKeywords = ({ keywords, onKeywordSelect }) => {
  const [suggestedKeywords, setSuggestedKeywords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [creditsRemaining, setCreditsRemaining] = useState(null);

  // Debounce function to limit API calls
  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const fetchKeywordSuggestions = async (seed) => {
    if (!seed || seed.length < 3) return; // Don't fetch for very short keywords
    
    setIsLoading(true);
    setError(null);
    
    try {
      const formData = new URLSearchParams();
      formData.append('dataSource', 'gkp');
      formData.append('country', 'us');
      formData.append('currency', 'USD');
      formData.append('kw[]', seed);

      const response = await axios.post(
        'https://api.keywordseverywhere.com/v1/get_keyword_data',
        formData,
        {
          headers: {
            'Authorization': 'Bearer 64ddedad9fb91c0d6383',
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      if (response.data?.credits) {
        setCreditsRemaining(response.data.credits);
      }

      if (response.data?.data) {
        setSuggestedKeywords(response.data.data.map(item => ({
          keyword: item.keyword,
          volume: item.vol,
          cpc: item.cpc,
          competition: item.competition
        })));
      }
    } catch (err) {
      if (err.response?.status === 402) {
        setError('Insufficient credits. Please check your Keywords Everywhere account.');
      } else {
        setError(err.response?.data?.message || 'Failed to fetch keyword suggestions');
      }
      console.error('Error fetching keywords:', err);
    } finally {
      setIsLoading(false);
    }
  };

  // Debounced version of fetchKeywordSuggestions
  const debouncedFetch = React.useCallback(
    debounce(fetchKeywordSuggestions, 1000),
    []
  );

  useEffect(() => {
    if (keywords.length > 0) {
      debouncedFetch(keywords[0]);
    }
  }, [keywords[0], debouncedFetch]);

  if (error && error.includes('Insufficient credits')) {
    return (
      <div className="mt-6 p-4 bg-yellow-50 border border-yellow-200 rounded-lg">
        <p className="text-yellow-800 text-sm">
          {error} <a href="https://keywordseverywhere.com/credits.html" target="_blank" rel="noopener noreferrer" className="underline">Purchase more credits</a>
        </p>
      </div>
    );
  }

  return ( 
    <div className="mt-6"> 
      <div className="flex justify-between items-center mb-2">
        <h3 className="text-sm font-medium text-gray-700">Suggested Keywords</h3>
        {creditsRemaining && (
          <span className="text-xs text-gray-500">Credits remaining: {creditsRemaining}</span>
        )}
      </div>
      <div className="flex flex-wrap gap-2 p-4 bg-gray-50 rounded-lg">
        {isLoading && <span className="text-gray-500">Loading suggestions...</span>}
        {error && <span className="text-red-500">{error}</span>}
        {suggestedKeywords.map((suggestion) => (
          <button
            key={suggestion.keyword}
            onClick={() => !keywords.includes(suggestion.keyword) && onKeywordSelect(suggestion.keyword)}
            className={`group relative px-3 py-1 rounded-full text-sm font-medium transition-colors
              ${keywords.includes(suggestion.keyword)
                ? 'bg-primary-100 text-primary-700'
                : 'bg-white border border-gray-300 text-gray-700 hover:border-primary-500 hover:text-primary-700'
              }`}
          >
            {suggestion.keyword}
            <div className="invisible group-hover:visible absolute bottom-full left-1/2 transform -translate-x-1/2 p-2 bg-gray-800 text-white text-xs rounded mb-2 whitespace-nowrap z-10">
              <div>Volume: {suggestion.volume}</div>
              <div>CPC: {suggestion.cpc?.currency}{suggestion.cpc?.value}</div>
              <div>Competition: {(suggestion.competition * 100).toFixed(1)}%</div>
            </div>
          </button>
        ))}
      </div>

      
    </div>
  );
};

export default SuggestedKeywords;