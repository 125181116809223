import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getAssistantId,
  getVectorStoreId,
  createChatSession,
  updateChatSession,
  getChatSessions,
  createThread,
  addMessageToThread,
  runAssistant,
  checkRunStatus,
  listMessages,
  saveChatMessage,
  getChatMessages
} from '../../api';
import { UserIcon, PaperAirplaneIcon, StopIcon } from '@heroicons/react/24/solid';
import reisloSymbol from '../../img/logo-green-symbol.svg';
import Waiter from './waiter';
import { setupAssistantPrompt } from './utilities';
import ReactMarkdown from 'react-markdown';
import SourceDrawer from './SourceDrawer';

const SingleChat = ({ sessionId: propSessionId, threadId: propThreadId }) => {
  const { sessionId: paramSessionId } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(propThreadId || null);
  const [sessionId, setSessionId] = useState(propSessionId || paramSessionId || null);
  const messagesEndRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const textAreaRef = useRef(null);
  const [selectedSource, setSelectedSource] = useState('');
  const [selectedCitation, setSelectedCitation] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [streamingMessage, setStreamingMessage] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [abortController, setAbortController] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    if (!token) {
      navigate('/login');
    } else if (sessionId) {
      loadChatMessages();
    }
  }, [navigate, sessionId]);

  useEffect(() => {
    const initializeThread = async () => {
      if (threadId) {
        try {
          await setupAssistantPrompt(threadId);
        } catch (error) {
          // console.error('Error setting up assistant prompt:', error);
        }
      }
    };

    initializeThread();
  }, [threadId]);

  useEffect(() => {
    const initializeNewChat = async () => {
      if (sessionId === 'new') {
        try {
          const assistantId = await getAssistantId();
          const vectorStoreId = await getVectorStoreId();
          const newThread = await createThread();
          const newSession = await createChatSession(
            'New Chat',
            newThread,
            assistantId,
            vectorStoreId
          );
          setThreadId(newThread);
          setSessionId(newSession.id);
          navigate(`/chat/${newSession.id}`, { replace: true });
          await setupAssistantPrompt(newThread.id);
        } catch (error) {
          // console.error('Error initializing new chat:', error);
        }
      }
    };

    initializeNewChat();
  }, [sessionId, navigate]);

  const loadChatMessages = async () => {
    try {
      setLoading(true);

      if (sessionId === 'new') {
        setMessages([]);
        return;
      }

      // First, fetch the chat session data
      const sessionData = await getChatSessions(1, sessionId);

      if (Array.isArray(sessionData) && sessionData.length > 0) {
        const chatSession = sessionData.find(session => session.id === sessionId);
        if (chatSession) {
          // Set the thread ID from the session data
          if (chatSession.thread_id) {
            setThreadId(chatSession.thread_id);
          } else {
            // console.warn('No thread ID found in the loaded session');
            // Create a new thread if it's missing
            const newThread = await createThread();
            if (newThread && newThread.id) {
              setThreadId(newThread.id);
              await setupAssistantPrompt(newThread.id);
              await updateChatSession(sessionId, { thread_id: newThread.id });
            } else {
              throw new Error('Failed to create a new thread');
            }
          }

          // Now, fetch the messages for this session
          const messagesData = await getChatMessages(sessionId);

          if (Array.isArray(messagesData)) {
            setMessages(messagesData.map(message => ({
              text: message.content,
              sender: message.role
            })));
          } else {
            // console.warn('No messages found or unexpected format for session ID:', sessionId);
          }
        } else {
          // console.warn('No matching chat session found for ID:', sessionId);
        }
      } else {
        // console.warn('No chat session data found or invalid data structure');
      }
    } catch (error) {
      // console.error('Error loading chat messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const streamText = async (text, speed = 10) => {
    setIsStreaming(true);
    setStreamingMessage('');
    
    // Create a flag to track if streaming should continue
    let shouldContinue = true;
    
    // Update the stop handler to control streaming
    const stopStreaming = () => {
      shouldContinue = false;
      setIsStreaming(false);
      setStreamingMessage('');
      setMessages(prevMessages => [...prevMessages, { text, sender: 'assistant' }]);
      setTimeout(scrollToBottom, 100);
    };
    
    // Set the stop handler
    setAbortController({ abort: stopStreaming });
    
    for (let i = 0; i < text.length; i++) {
      if (!shouldContinue) break;
      
      await new Promise(resolve => setTimeout(resolve, speed));
      setStreamingMessage(prev => prev + text[i]);
      if (i % 3 === 0) {
        scrollToBottom();
      }
    }
    
    if (shouldContinue) {
      setIsStreaming(false);
      setStreamingMessage('');
      setMessages(prevMessages => [...prevMessages, { text, sender: 'assistant' }]);
      setTimeout(scrollToBottom, 100);
    }
    
    setAbortController(null);
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;
    
    setIsProcessing(true);
    setMessages(prevMessages => [...prevMessages, { text: input, sender: 'user' }]);
    
    try {
      const assistantId = await getAssistantId();
      const vectorStoreId = await getVectorStoreId();

      if (!threadId || !sessionId) {
        throw new Error('Thread or session not initialized');
      }

      // Add user message to the thread (OpenAI)
      const messageResponse = await addMessageToThread(threadId, {
        role: 'user',
        content: input,
      });

      // If a new thread was created, update the thread ID
      if (messageResponse.new_thread_id) {
        const newThreadId = messageResponse.new_thread_id;
        setThreadId(newThreadId);
        
        // Update the session with the new thread ID
        await updateChatSession(sessionId, {
          thread_id: newThreadId,
          date_updated: new Date().toISOString()
        });
      }

      // Save user message to Directus
      await saveChatMessage(sessionId, {
        role: 'user',
        content: input,
        order: messages.length
      });

      setInput('');
      adjustTextAreaHeight(true);

      // Run the assistant with the current Vector Store ID
      const runId = await runAssistant(threadId, assistantId, {
        file_search: vectorStoreId ? { vector_store_ids: [vectorStoreId] } : undefined,
        instructions: "Please provide a helpful response based on the context."
      });

      // Check run status and wait for completion
      let runStatus;
      let statusCheckCount = 0;
      const maxStatusChecks = 30;

      do {
        if (statusCheckCount >= maxStatusChecks) {
          throw new Error('Assistant run timed out after maximum attempts');
        }

        const runData = await checkRunStatus(threadId, runId);
        runStatus = runData.status;
        
        if (runStatus === 'failed' || runStatus === 'cancelled' || runStatus === 'expired') {
          throw new Error(`Assistant run ${runStatus}`);
        }
        
        await new Promise(resolve => setTimeout(resolve, 2000));
        statusCheckCount++;
      } while (runStatus === 'in_progress' || runStatus === 'queued');

      // Add retry logic for message fetching
      let assistantMessage = null;
      let retryCount = 0;
      const maxRetries = 5;
      const retryDelay = 3000;

      while (retryCount < maxRetries) {
        const messagesRes = await listMessages(threadId);
        const assistantMessages = messagesRes.data
          .filter(msg => msg.role === 'assistant')
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        assistantMessage = assistantMessages[0];

        if (assistantMessage?.content?.[0]?.text?.value) {
          break;
        }

        await new Promise(resolve => setTimeout(resolve, retryDelay));
        retryCount++;
      }

      if (!assistantMessage?.content?.[0]?.text?.value) {
        throw new Error('No valid assistant response received after multiple attempts');
      }

      const assistantResponse = assistantMessage.content[0].text.value;

      // Save assistant message to Directus
      await saveChatMessage(sessionId, {
        role: 'assistant',
        content: assistantResponse,
        order: messages.length + 1
      });

      // Bezpośrednie dodanie odpowiedzi asystenta do wiadomości (bez streamingu)
      setMessages(prevMessages => [...prevMessages, { text: assistantResponse, sender: 'assistant' }]);
      
      // Update chat session title in Directus
      await updateChatSession(sessionId, {
        title: input.slice(0, 50) + '...',
        date_updated: new Date().toISOString()
      });

    } catch (error) {
      // console.error('An error occurred:', error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleStopGeneration = () => {
    setIsProcessing(false);
  };

  const adjustTextAreaHeight = (reset = false) => {
    if (textAreaRef.current) {
      if (reset) {
        textAreaRef.current.style.height = 'auto';
        setTextareaHeight('auto');
      } else {
        const scrollHeight = textAreaRef.current.scrollHeight;
        const maxHeight = 80; // Set your desired max height here (in pixels)
        const newHeight = Math.min(scrollHeight, maxHeight);
        setTextareaHeight(`${newHeight}px`);
      }
    }
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInput(value);
    
    // Auto-resize textarea
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto'; // Reset height
      // Oblicz liczbę linii i dostosuj maksymalną wysokość
      const lineCount = (value.match(/\n/g) || []).length + 1;
      const lineHeight = 24; // przybliżona wysokość jednej linii w pikselach
      const padding = 16; // dodatkowe odstępy
      const dynamicMaxHeight = Math.min(lineCount * lineHeight + padding, 300); // limit do ~12 linii
      
      const newHeight = Math.min(textAreaRef.current.scrollHeight, dynamicMaxHeight);
      setTextareaHeight(`${newHeight}px`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const renderMessage = (message) => {
    return (
      <ReactMarkdown
        components={{
          h3: ({ node, ...props }) => <h3 className="text-lg font-bold mt-4 " {...props} />,
          strong: ({ node, ...props }) => <strong className="font-bold" {...props} />,
          em: ({ node, ...props }) => <em className="italic" {...props} />,
          ul: ({ node, ...props }) => <ul className="list-disc list-inside my-4 pb-4" {...props} />,
          ol: ({ node, ...props }) => <ol className="list-decimal list-inside my-2" {...props} />,
          li: ({ node, ...props }) => <li className="ml-4" {...props} />,
          p: ({ node, ...props }) => {
            const children = React.Children.map(props.children, child => {
              if (typeof child === 'string') {
                return child.split(/(【\d+:\d+†[^】]+】)/g).map((part, index) => {
                  if (part.match(/【\d+:\d+†[^】]+】/)) {
                    const source = part.slice(1, -1); // Remove 【】
                    const citation = message.annotations?.find(annotation => 
                      annotation.file_citation?.quote && annotation.text === part
                    )?.file_citation?.quote || '';
                    
                    return (
                      <span
                        key={index}
                        className="text-primary-500 cursor-pointer hover:underline ml-2"
                        onClick={() => {
                          setSelectedSource(source);
                          setSelectedCitation(citation);
                          setDrawerOpen(true);
                        }}
                      >
                         Source
                      </span>
                    );
                  }
                  return part;
                });
              }
              return child;
            });
            return <p {...props}>{children}</p>;
          }
        }}
      >
        {message.text}
      </ReactMarkdown>
    );
  };

  return (
    <div className="flex flex-col h-screen pr-8 bg-white">
      <div className="max-w-5xl mx-auto pb-48 pt-8 w-full">
        <div className="flex-1 p-4 w-full">
          <div className="p-4 rounded mb-4 h-full overflow-y-auto w-full prose max-w-none">
            {messages.map((message, index) => (
              <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                <div className={`flex items-start ${message.sender === 'user' ? 'flex-row-reverse' : ''}`}>
                  <div className="flex-shrink-0 mr-4">
                    {message.sender === 'user' ? (
                      <div className="w-8 h-8 rounded-full bg-secondary-50 flex items-center justify-center">
                        <UserIcon className="w-5 h-5 text-secondary-500" />
                      </div>
                    ) : (
                      <img
                        src={reisloSymbol}
                        alt="AI avatar"
                        className="w-8 h-8 rounded-full bg-primary-100 p-1.5 mt-6 mr-0"
                      />
                    )}
                  </div>
                  <div className={`bg-grey-100 rounded-2xl max-w-3xl py-0 p-4 ${message.sender === 'user' ? 'mr-2 bg-primary-500 text-white rounded-tr-sm' : 'ml-2  rounded-tl-sm'}`}>
                    <div className="text-base">
                      {renderMessage(message)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {isProcessing && (
              <div className="mb-2 w-full">
                <div className="flex items-start w-full">
                  <div className="flex-shrink-0 mr-2">
                    <img
                      src={reisloSymbol}
                      alt="AI avatar"
                      className="w-8 h-8 rounded-full bg-gray-200 p-1 m-0"
                    />
                  </div>
                  <div className="flex-grow">
                    <Waiter />
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className="w-full left-0 mx-auto fixed pb-4 bg-white  bottom-0">
          <div className="max-w-5xl mx-auto rounded-lg bg-white p-3 border-1 border-grey-200  w-full pb-0 shadow-lg mb-4">
            <div className="relative w-full">
              <textarea
                ref={textAreaRef}
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                style={{
                  height: textareaHeight,
                  maxHeight: '300px',
                  overflowY: 'auto',
                  paddingRight: '60px',
                  lineHeight: '24px',
                  minHeight: '56px',
                  backgroundColor: 'white'
                }}
                className="shadow-none ring-0 w-full py-4 px-4 resize-none mb-3 bg-white border-none focus:border-none focus:ring-0 focus:outline-none disabled:bg-white"
                placeholder="Type your message..."
                rows={1}
                disabled={isProcessing}
              />
              {isProcessing ? (
                <button
                  onClick={handleStopGeneration}
                  className="absolute right-0 top-1/2 -translate-y-1/2 bg-gray-600 flex text-white p-2 rounded-full hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-50 active:bg-gray-700 border border-gray-600 transition duration-150 ease-in-out"
                >
                  <StopIcon className="w-5 h-5" />
                </button>
              ) : (
                <button
                  onClick={handleSendMessage}
                  className={`absolute right-0 top-1/2 -translate-y-1/2 bg-primary-500 flex text-white p-2 rounded-full hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 active:bg-primary-700 border border-primary-500 transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed ${
                    !input.trim() || isProcessing ? 'bg-gray-400 cursor-not-allowed' : ''
                  }`}
                  disabled={loading || !input.trim() || isProcessing}
                >
                  {loading ? 'Waiting...' : <PaperAirplaneIcon className="w-5 h-5" />}
                </button>
              )}
            </div>
          </div>
          <p className="text-xs text-gray-500 mt-3 text-center max-w-4xl mx-auto ">
            Our AI assistant strives for accuracy, but may not always be perfect. For the best results, we recommend double-checking important information with the AI or through independent research. Your thoughtful engagement ensures the most reliable outcomes.
          </p>
        </div>
      </div>
      <SourceDrawer 
        open={drawerOpen}
        setOpen={setDrawerOpen}
        source={selectedSource}
        citation={selectedCitation}
      />
    </div>
  );
};

export default SingleChat;