import React, { useState, useEffect, useCallback } from 'react';
import { listOpenAIFiles, getVectorStoreId } from '../../api';
import { format } from 'date-fns';
import ContentHeader from '../Atoms/contentHeader';
import provideDataIcon from '../../img/provide-data.svg';
import { PlusIcon } from '@heroicons/react/20/solid';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const title = 'My Files';
const description = 'List of all files uploaded to prose captain that can be used with Assistants and Content Creator.';
const primaryAction = 'Add File';

const SkeletonRow = React.memo(() => (
  <tr className="animate-pulse">
    <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-6">
      <div className="h-8 bg-gray-200 rounded w-3/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/4"></div>
    </td>
    <td className="whitespace-nowrap px-3 py-4">
      <div className="h-8 bg-gray-200 rounded w-1/2"></div>
    </td>
  </tr>
));

const EmptyState = ({ onPrimaryClick }) => (
  <div className="flex flex-col items-center justify-center h-full text-center py-24">
    <img 
      src={provideDataIcon} 
      alt="Upload File" 
      className="mx-auto h-32 w-32 text-orange-400"
    />
    <h1 className="text-3xl font-medium text-gray-900 mt-6">No files uploaded yet</h1>
    <p className="mt-1 text-base text-gray-800 opacity-80">Start by uploading files to use with OpenAI Assistants.</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onPrimaryClick}
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-full text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Upload File
      </button>
    </div>
  </div>
);

const DatasetsTable = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [debugInfo, setDebugInfo] = useState({
    vectorStoreId: null,
    assistantId: null,
    vectorStoreFiles: []
  });
  const navigate = useNavigate();
  const userId = localStorage.getItem('user_id');
  const isLocalhost = window.location.hostname === 'localhost';

  const primaryClick = useCallback(() => navigate('/datasets/create-dataset'), [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const filesResponse = await listOpenAIFiles(userId);
        setFiles(filesResponse.data || []);
      } catch (err) {
        console.error('Error in component:', err);
        setError(err.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    const fetchDebugInfo = async () => {
      if (!isLocalhost) return;
      
      try {
        // Fetch Vector Store ID
        const vectorStoreId = await getVectorStoreId();
        
        // Fetch Assistant ID (assuming you have this endpoint)
        const assistantId = process.env.REACT_APP_OPENAI_ASSISTANT_ID;
        
        // Fetch Vector Store Files
        const vectorStoreResponse = await axios.get(
          `https://api.openai.com/v1/vector_stores/${vectorStoreId}/files`,
          {
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
              'OpenAI-Beta': 'assistants=v2'
            }
          }
        );

        setDebugInfo({
          vectorStoreId,
          assistantId,
          vectorStoreFiles: vectorStoreResponse.data.data || []
        });
      } catch (err) {
        console.error('Debug info fetch error:', err);
      }
    };

    fetchDebugInfo();
  }, [isLocalhost]);

  const formatFileSize = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    if (bytes === 0) return '0 Byte';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
  };

  const formatDate = (timestamp) => {
    return format(new Date(timestamp * 1000), 'MMM d, yyyy HH:mm');
  };

  const renderDebugInfo = () => {
    if (!isLocalhost) return null;

    return (
      <div className="mt-8 p-4 bg-gray-100 rounded-lg">
        <h3 className="text-lg font-semibold mb-4">Debug Information (Localhost Only)</h3>
        <div className="space-y-2">
          <p><strong>Vector Store ID:</strong> {debugInfo.vectorStoreId || 'Loading...'}</p>
          <p><strong>Assistant ID:</strong> {debugInfo.assistantId || 'Loading...'}</p>
          
          <div className="mt-4">
            <h4 className="font-medium mb-2">Vector Store Files:</h4>
            {debugInfo.vectorStoreFiles.length > 0 ? (
              <ul className="list-disc pl-5">
                {debugInfo.vectorStoreFiles.map((file, index) => (
                  <li key={index} className="text-sm">
                    {file.id} - {file.filename} ({file.status})
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray-500">No files in vector store</p>
            )}
          </div>

          <div className="mt-4 p-3 bg-blue-50 rounded">
            <h4 className="font-medium mb-2">Storage Information:</h4>
            <p className="text-sm">
              Files are stored in both:
              <ul className="list-disc pl-5 mt-1">
                <li>Directus (for file management and metadata)</li>
                <li>OpenAI Vector Store (for AI processing and embeddings)</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  };

  if (loading) return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <ContentHeader
          title={title}
          description={description}
          primaryAction={primaryAction}
          onPrimaryAction={primaryClick}
        />
        <div className="mt-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    File Name
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Purpose
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Size
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Created At
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {Array.from({ length: 5 }).map((_, index) => (
                  <SkeletonRow key={index} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  if (error) return (
    <div className="flex justify-center items-center h-64">
      <div className="text-red-500">Error: {error}</div>
    </div>
  );

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <ContentHeader
          title={title}
          description={description}
          primaryAction={primaryAction}
          onPrimaryAction={primaryClick}
        />

        <div className="mt-8">
          {files.length === 0 ? (
            <EmptyState onPrimaryClick={primaryClick} />
          ) : (
            <div>
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        File Name
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Purpose
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Size
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Created At
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>

                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {files.map((file) => (
                      <tr key={file.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <button
                            onClick={() => navigate(`/datasets/${file.id}`)}
                            className="text-primary-500 hover:text-primary-700"
                          >
                            {file.filename}
                          </button>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {file.purpose}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {formatFileSize(file.bytes)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {formatDate(file.created_at)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            file.status === 'processed' 
                              ? 'bg-green-100 text-green-800' 
                              : 'bg-yellow-100 text-yellow-800'
                          }`}>
                            {file.status}
                          </span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <button
                            onClick={() => navigate(`/datasets/${file.id}`)}
                            className="text-primary-500 hover:text-primary-700 font-medium"
                          >
                            View Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {renderDebugInfo()}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DatasetsTable;