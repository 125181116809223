import axios from 'axios';
import { checkRunStatus, listMessages, apiHeaders } from '../articlesGpt';
import { DIRECTUS_INSTANCE } from '../../../api'; // Make sure this import is correct
import { getAssistantId } from '../../../api'; // Added import for getAssistantId

import { generateBlogPostPrompt } from '../prompts/blogPostPrompt';
import { generateSocialMediaPostPrompt } from '../prompts/socialMediaPostPrompt';
import { generateEcommerceProductDescriptionPrompt } from '../prompts/ecommerceProductDescriptionPrompt';
import { generateProductReviewPrompt } from '../prompts/productReviewPrompt';


const generateArticle = async (articleId, articleDraft, keywords, toneOfVoice, complexity, language, contentType) => {
  try {
    // Fetch the article data from Directus to get the thread_id
    const articleResponse = await axios.get(`${DIRECTUS_INSTANCE}/items/articles/${articleId}`);
    const articleData = articleResponse.data.data;
    const threadId = articleData.thread_id;

    if (!threadId) {
      throw new Error('No thread_id found for this article. Please ensure the article draft was saved correctly.');
    }



    // Fetch the assistant ID
    const assistantId = await getAssistantId();

    // Parse the articleDraft if it's a string
    const parsedDraft = typeof articleDraft === 'string' ? JSON.parse(articleDraft) : articleDraft;

    // Extract the outline from the parsed draft
    const outline = parsedDraft.blocks
      .map(block => {
        if (block.type === 'header') {
          return `${'#'.repeat(block.data.level)} ${block.data.text}`;
        } else if (block.type === 'paragraph') {
          return block.data.text;
        }
        return ''; // Return empty string for other block types
      })
      .join('\n\n');

    // Use the content type from Directus if not provided
    contentType = contentType || articleData.content_type || 'blogpost';

    let prompt;
    switch (contentType.toLowerCase()) {
      case 'blogpost':
        prompt = generateBlogPostPrompt(outline, keywords, toneOfVoice, complexity, language);
        break;
      case 'social media post':
        prompt = generateSocialMediaPostPrompt(outline, keywords, toneOfVoice, complexity, language);
        break;
      case 'short ecommerce product description':
        prompt = generateEcommerceProductDescriptionPrompt(outline, keywords, toneOfVoice, complexity, language);
        break;
      case 'product review':
        prompt = generateProductReviewPrompt(outline, keywords, toneOfVoice, complexity, language);
        break;
      default:
        console.warn(`Unsupported content type: ${contentType}. Falling back to blogpost.`);
        prompt = generateBlogPostPrompt(outline, keywords, toneOfVoice, complexity, language);
    }

    const messages = [
      {
        role: 'assistant',
        content: `I am a content copywriter and my job is to write ${contentType} in ${language}`
      },
      {
        role: 'user',
        content: `Here's the outline:\n\n${outline}\n\n  . I will send you requirements in the next message.`
      },
      {
        role: 'assistant',
        content: `I will use ONLY (this is very important!!!) data from the files. If I will not find numeric excact data, I will not use it. `
      },

      {
        role: 'user',
        content: ` On the bottom please create a bibliography of your sources mentioning the source【】and the title / filename / page of the source. `
      },
      {
        role: 'user',
        content: prompt
      }
    ];

    for (const message of messages) {
      await axios.post(`https://api.openai.com/v1/threads/${threadId}/messages`, message, { headers: apiHeaders });
    }

    const runRes = await axios.post(`https://api.openai.com/v1/threads/${threadId}/runs`, {
      assistant_id: assistantId
    }, { headers: apiHeaders });
    const runId = runRes.data.id;
 
    let runData;
    while (true) {
      runData = await checkRunStatus(threadId, runId);
      if (runData.status === 'completed') break;
      if (runData.status === 'failed') throw new Error('Assistant run failed.');
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    const messagesRes = await listMessages(threadId);


    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const generatedContent = assistantMessage.content[0].text.value;

    return generatedContent;
  } catch (error) {
    console.error('Error in generating article:', error);
    throw new Error(`Failed to generate article: ${error.message}`);
  }
};

export default generateArticle;