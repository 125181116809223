export const generateProductReviewPrompt = (keywords, toneOfVoice, complexity) => {
  return `
    You are a professional product reviewer tasked with creating a comprehensive review of at least 100 words. Follow these instructions carefully:

    1. Review Structure:
       - Use the provided outline as a guide, but expand on each point.
       - Main Sections: Use H2 headings for main sections as in the draft.
       - Subsections: Use H3 headings for subsections where necessary.
       - Paragraphs: Each section should have multiple paragraphs. Aim for 2-3 paragraphs per main section.
       - Use bullet points and numbered lists where appropriate to improve readability.

    2. Content Depth:
       - Provide detailed explanations for each aspect of the product.
       - Include specific examples from your testing and usage experience.
       - Address potential questions or concerns your readers might have about the product.

    3. Keyword Usage:
       - Incorporate the product name and key features naturally throughout the review.
       - Ensure the product name is in the H1 title and at least one H2 heading.
       - Main keywords: ${keywords.join(', ')}
       - Aim for a keyword density of approximately 2%.
       - Make the product name bold in the text.

    4. Review Components:
       - Title (H1): Create an engaging title that includes the product name.
       - Introduction (100-150 words): Hook the reader and provide a brief overview of the product and your overall impression.
       - Body: Develop each section fully, connecting ideas logically.
       - Add pros and cons section: build a table with pros and cons of the product.
       - Conclusion (100-150 words): Summarize key points and provide a clear recommendation.

    5. Writing Style:
       - Tone of Voice: ${toneOfVoice}
       - Complexity: Aim for a Fog Index of ${complexity}
       - Use a balanced tone that's neither overly promotional nor overly critical.

    6. Engagement:
       - Use comparisons to help readers understand the product's features and performance.
       - Include a clear rating system (e.g., out of 5 stars) and explain your rationale.

    7. Objectivity:
       - Clearly separate facts about the product from your personal opinions.
       - Disclose any potential biases or conflicts of interest.
       - Provide balanced coverage of both positive and negative aspects.

    8. Technical Details:
       - Include relevant specifications and technical details.
       - Explain technical terms in a way that's accessible to the average reader.
       - Compare technical aspects to industry standards or competing products.

    9. User Perspective:
       - Address how well the product meets the needs of its target audience.

    Write the product review now, using markdown formatting where necessary. Focus solely on producing the content without any additional comments or explanations. Ensure your review is comprehensive, engaging, and helpful for potential buyers.
  `;
};