import React, { useEffect, useState } from 'react';

const title = 'Pricing Table';
const description = 'This is pricing table';

export default function PricingTable() {
  const [userId, setUserId] = useState(null);
  
  useEffect(() => {
    // Get the current user ID from localStorage
    const currentUserId = localStorage.getItem('user_id');
    setUserId(currentUserId);
    
    // Load the Stripe pricing table script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Clean up on component unmount
    return () => {
      if (script.parentNode) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const successUrl = `${window.location.origin}/profile?session_id={CHECKOUT_SESSION_ID}`;

  return (
    <div className="mx-auto max-w-6xl">
      <div className="sm:flex-auto mt-16">
        <h1 className="text-4xl font-medium leading-6 text-gray-900 mt-8">{title}</h1>
        <p className="mt-6 text-base text-gray-600">
          {description}
        </p>
        <div className="mt-2 mb-6 inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-blue-100 text-blue-800">
          Testowe środowisko - prawdziwe transakcje nie będą przetwarzane
        </div>
      </div>

      <div className="mt-8 shadow rounded-lg py-16 bg-white">
        <stripe-pricing-table 
          pricing-table-id="prctbl_1PIZqFIldhZa7aOZ8xXd8JY3"
          publishable-key="pk_test_51PIZkwIldhZa7aOZonl5EzyXP3UZT39dgtVeYlIstIhPg7uk2kFNf0ibdgf5uuMFLBGcApN2QMyawtiBrxc6DHCl00kjFfPbLu"
          client-reference-id={userId}
          success-url={successUrl}>
        </stripe-pricing-table>
      </div>
    </div>
  );
}
