import React, { useState, useRef, useEffect, useCallback } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../../api';
import generateArticleStructure from './generateArticleStructure';
import parseAndRenderEditorContent from './parseRender';
import { useNavigate } from 'react-router-dom';
import { saveArticleToDirectus, updateArticleStatusToOngoing } from '../saveArticle';
import generateArticle from '../SingleArticle/generateArticle';
import debounce from 'lodash/debounce';
import FullScreenLoader from './FullScreenLoader';
import { Switch } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import KeywordsTable from './Keywords/KeywordsTable';
import { searchScholarSources } from './Sources/SourcesSection';
import SuggestedKeywords from './Keywords/SuggestedKeywords';
import SourcesSection from './Sources/SourcesSection';

// Add this helper function at the top of the file, outside the component
const isLocalhost = () => {
  return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
};

export default function CreateArticleDraft() {
  const [articleBody, setArticleBody] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [contentType, setContentType] = useState('blogpost');
  const [toneOfVoice, setToneOfVoice] = useState('friendly');
  const [complexity, setComplexity] = useState('fog index: 10');
  const [language, setLanguage] = useState('polish');
  const [loading, setLoading] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const [isDraftGenerated, setIsDraftGenerated] = useState(false);
  const [generatedArticleId, setGeneratedArticleId] = useState(null);
  const [articleData, setArticleData] = useState(null);
  const [editorHasContent, setEditorHasContent] = useState(false);
  const [articleContext, setArticleContext] = useState('');
  const [isAutosaving, setIsAutosaving] = useState(false);
  const [userHasEditedEditor, setUserHasEditedEditor] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [fullPageView, setFullPageView] = useState(false);
  const [generatedArticle, setGeneratedArticle] = useState('');
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [loaderType, setLoaderType] = useState('');
  const [selectAllKeywords, setSelectAllKeywords] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [isLoadingProjects, setIsLoadingProjects] = useState(false);

  const navigate = useNavigate();
  const editorRef = useRef(null);

  const directusAPI = axios.create({
    baseURL: DIRECTUS_INSTANCE,
  });

  directusAPI.interceptors.request.use(config => {
    const token = localStorage.getItem('directus_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, error => Promise.reject(error));

  const title = "Create Article Draft";
  const description = "Create compelling drafts or full articles with our powerful generator. Transform your ideas into engaging content effortlessly!";

  // Replace all console.log calls with this custom logging function
  const log = (...args) => {
    if (isLocalhost()) {
      console.log(...args);
    }
  };

  const handleContinue = async () => {
    if (editorHasContent) {
      try {
        setIsFullScreenLoading(true);
        setLoaderType('article');
        const outputData = await editorRef.current.save();

        let articleId = generatedArticleId;
        let threadId = articleData?.thread_id;

        if (!articleId) {
          const newArticle = await saveArticleToDirectus({
            title: outputData.blocks[0]?.data?.text || 'Untitled',
            article_draft: JSON.stringify(outputData),
            keywords: keywords,
            content_type: contentType,
            tone_of_voice: toneOfVoice,
            fog_index: complexity,
            language: language,
            thread_id: threadId,
            project: {
              id: selectedProject
            }
          });
          articleId = newArticle.data.id;
          setGeneratedArticleId(articleId);
        } else {
          await saveArticleToDirectus({
            id: articleId,
            article_draft: JSON.stringify(outputData),
            thread_id: threadId,
            project: selectedProject
          });
        }

        await updateArticleStatusToOngoing(articleId);

        const response = await directusAPI.get(`/items/articles/${articleId}`);
        const currentArticleData = response.data.data;
        threadId = currentArticleData.thread_id;

        if (!threadId) {
          throw new Error('No thread_id found for this article. Please ensure the article draft was saved correctly.');
        }

        const generatedContent = await generateArticle(
          articleId,
          outputData,
          keywords,
          toneOfVoice,
          complexity,
          language,
          contentType
        );

        setGeneratedArticle(generatedContent);
        setFullPageView(true);

        await saveArticleToDirectus({
          id: articleId,
          article_body: generatedContent,
          status: 'published',
          title: outputData.blocks[0]?.data?.text || currentArticleData.title,
          slug: currentArticleData.slug,
          fog_index: complexity,
          keywords: keywords,
          content_type: contentType,
          tone_of_voice: toneOfVoice,
          language: language,
          article_draft: JSON.stringify(outputData),
          thread_id: threadId,
          project: selectedProject
        }, true);

        log('Article generated and saved successfully');
        setSaveError('');
        navigate('/articles');
      } catch (error) {
        setSaveError('Error generating article: ' + error.message);
      } finally {
        setIsFullScreenLoading(false);
      }
    }
  };

  const handleSave = useCallback(async (isAutosave = false) => {
    if (editorRef.current && editorRef.current.save) {
      try {
        setIsAutosaving(true);
        const outputData = await editorRef.current.save();

        const articleDataToSave = {
          id: generatedArticleId,
          title: outputData.blocks[0]?.data?.text || 'Untitled',
          article_draft: JSON.stringify(outputData),
          keywords: keywords,
          content_type: contentType,
          tone_of_voice: toneOfVoice,
          fog_index: complexity,
          language: language,
          thread_id: articleData?.thread_id || null,
          project: selectedProject || null,
        };

        const savedArticle = await saveArticleToDirectus(articleDataToSave);
        log('Saved article:', savedArticle);

        if (!isAutosave) {
          setGeneratedArticleId(savedArticle.data.id);
          setArticleData(savedArticle.data);
          setLastSaved(new Date().toISOString());
          setSaveError('');
          setEditorHasContent(true);
          setIsDraftGenerated(true);
        }

        log('Article draft ' + (isAutosave ? 'auto' : '') + 'saved successfully');
      } catch (error) {
        setSaveError('Error during saving: ' + error.message);
      } finally {
        setIsAutosaving(false);
      }
    }
  }, [generatedArticleId, keywords, contentType, toneOfVoice, complexity, language, articleData, selectedProject]);

  const debouncedSave = useCallback(
    debounce(() => {
      handleSave(true);
    }, 2000),
    [handleSave]
  );

  useEffect(() => {
    if (!editorRef.current && document.getElementById('editorjs')) {
      editorRef.current = new EditorJS({
        holder: 'editorjs',
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['link'],
            config: {
              placeholder: 'Enter a header',
              levels: [1, 2, 3],
              defaultLevel: 2
            }
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
        },
        onChange: () => {
          if (editorRef.current && editorRef.current.save) {
            editorRef.current.save().then((outputData) => {
              setEditorHasContent(outputData.blocks.length > 0);
              if (generatedArticleId) {
                debouncedSave();
              }
            });
          }
        },
        data: articleData?.article_draft ?
          (typeof articleData.article_draft === 'string' ?
            JSON.parse(articleData.article_draft) :
            articleData.article_draft) :
          {},
      });
    }

    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [debouncedSave, generatedArticleId, articleData]);

  useEffect(() => {
    const fetchProjects = async () => {
      setIsLoadingProjects(true);
      try {
        const userId = localStorage.getItem('user_id');
        const response = await directusAPI.get('/items/projects', {
          params: {
            filter: { owner: userId }
          }
        });
        setProjects(response.data.data);
      } catch (error) {
      } finally {
        setIsLoadingProjects(false);
      }
    };

    fetchProjects();
  }, []);

  const autoGenerateAndRender = async () => {
    setIsFullScreenLoading(true);
    setLoaderType('draft');

    try {
      // Run article structure generation first
      const structureResult = await generateArticleStructure(
        articleContext,
        keywords,
        toneOfVoice,
        complexity,
        language,
        contentType
      );

      // Try to fetch sources in parallel but don't wait for it if it fails
      searchScholarSources(articleContext, keywords).catch(err => {
      });

      const { generatedText, threadId } = structureResult;

      await parseAndRenderEditorContent(generatedText, editorRef);
      const outputData = await editorRef.current.save();

      const articleDataToSave = {
        title: outputData.blocks[0]?.data?.text || 'Untitled',
        article_draft: JSON.stringify(outputData),
        keywords: keywords,
        content_type: contentType,
        tone_of_voice: toneOfVoice,
        fog_index: complexity,
        language: language,
        thread_id: threadId,
        status: contentType === 'short ecommerce product description' ? 'published' : 'draft',
        project: selectedProject
      };

      const savedArticle = await saveArticleToDirectus(articleDataToSave);
      log('Saved article:', savedArticle);

      setGeneratedArticleId(savedArticle.data.id);
      setArticleData(savedArticle.data);
      setLastSaved(new Date().toISOString());
      setSaveError('');
      setIsDraftGenerated(true);
      setEditorHasContent(true);
      setShowEditor(true);

      if (editorRef.current) {
        const articleDraft = savedArticle.data.article_draft;
        const parsedDraft = typeof articleDraft === 'string' ? JSON.parse(articleDraft) : articleDraft;
        editorRef.current.render(parsedDraft);
      }

      // First, hide the loader
      setIsFullScreenLoading(false);

      // Then set all the states
      setIsDraftGenerated(true);
      setEditorHasContent(true);
      setShowEditor(true);

      // Finally, scroll after a small delay to ensure everything is rendered
      setTimeout(() => {
        const draftReviewSection = document.getElementById('draft-review');
        if (draftReviewSection) {
          draftReviewSection.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 100);

    } catch (error) {
      setSaveError('Error during generation or saving: ' + error.message);
      alert('There was an error generating the article. Please try again or contact support if the problem persists.');
    } finally {
      setIsFullScreenLoading(false);
    }
  };

  const handleSubmit = useCallback((event) => {
    if (event) event.preventDefault();

    if (!articleContext || articleContext.trim() === '') {
      setSaveError('Article context is empty. Please provide context for the article.');
      return;
    }

    // For e-commerce product descriptions and social media posts, we'll set status to published directly
    if (contentType === 'short ecommerce product description' || contentType === 'social media post') {
      setLoaderType('article');
    } else {
      setLoaderType('draft');
    }

    autoGenerateAndRender();
  }, [articleContext, autoGenerateAndRender, contentType]);


  const toggleAdvancedSettings = () => setShowAdvancedSettings(!showAdvancedSettings);

  const handleAddKeyword = () => {
    if (keywordInput && !keywords.includes(keywordInput)) {
      setKeywords([...keywords, keywordInput]);
      setKeywordInput('');
    }
  };

  const handleRemoveKeyword = (keyword) => {
    setKeywords(keywords.filter((k) => k !== keyword));
  };

  const handleSelectAll = (checked) => {
    setSelectAllKeywords(checked);
    // Logic to select/deselect all keywords will go here
  };

  const steps = [
    { name: 'Content setup', href: '#content-setup', status: 'current' },
    { name: 'Audience setup', href: '#audience-setup', status: 'upcoming' },
    { name: 'SEO Keywords', href: '#seo-keywords', status: 'upcoming' },
    { name: 'Sources', href: '#sources', status: 'upcoming' },
    { name: 'Draft Review', href: '#draft-review', status: 'upcoming' }
  ];

  const handleStepClick = (href) => {
    const element = document.querySelector(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  if (fullPageView) {
    return (
      <div className="min-h-screen bg-white p-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-8">{articleData?.title || 'Generated Article'}</h1>
          <div className="prose prose-lg" dangerouslySetInnerHTML={{ __html: generatedArticle }} />
          <button
            onClick={() => setFullPageView(false)}
            className="mt-8 bg-primary-500 text-white px-6 py-2 rounded-full hover:bg-primary-600"
          >
            Back to Editor
          </button>
        </div>
      </div>
    );
  }

  if (isFullScreenLoading) return <FullScreenLoader type={loaderType} />;

  return (
    <div className="lg:pl-24 w-full">
      <div className="mx-auto mt-16 max-w-7xl">
        <div className="sm:flex sm:items-center mb-8">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-medium leading-6 text-neutral-900">{title}</h1>
            <p className="mt-5 text-base text-neutral-800 opacity-80 max-w-4xl">{description}</p>
          </div>
        </div>

        <div className="flex gap-12">
          {/* Main Content */}
          <div className="max-w-5xl flex-1">
            {/* Project Selection */}
            <div className="mb-8">
              <h2 className="text-2xl font-medium mb-4">Project Selection</h2>
              <div className="">
                <label htmlFor="project" className="block text-sm font-medium text-gray-700 mb-2">
                  Select Project
                </label>
                <div className="relative">
                  <select
                    id="project"
                    value={selectedProject}
                    onChange={(e) => {
                      if (e.target.value === 'new') {
                        navigate('/projects/create-project');
                      } else {
                        setSelectedProject(e.target.value);
                      }
                    }}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  >
                    <option value="">Select a project</option>
                    {projects.map((project) => (
                      <option key={project.id} value={project.id}>
                        {project.project_name}
                      </option>
                    ))}
                    <option value="new">➕ Create New Project</option>
                  </select>
                  {isLoadingProjects && (
                    <div className="absolute right-10 top-1/2 transform -translate-y-1/2">
                      <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-primary-500"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Content Setup Section */}
            <section id="content-setup" className="mb-16">
              <h2 className="text-2xl font-medium mt-6 mb-4">Content Setup</h2>

              {/* Content Type Selectors */}
              <div className="mb-12">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
                  <div
                    onClick={() => setContentType('blogpost')}
                    className={`bg-white p-6 rounded-xl border cursor-pointer transition-all ${
                      contentType === 'blogpost' ? 'border-primary-500 bg-primary-50' : 'border-gray-200 hover:border-primary-200'
                    }`}
                  >
                    <div className="h-12 w-12 mb-4 text-primary-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                      </svg>
                    </div>
                    <h3 className="text-lg font-medium mb-2">Blog Post</h3>
                    <p className="text-sm text-gray-600">Create engaging, SEO-optimized blog content with structured sections.</p>
                  </div>

                  <div
                    onClick={() => setContentType('social media post')}
                    className={`bg-white p-6 rounded-xl border cursor-pointer hidden transition-all ${
                      contentType === 'social media post' ? 'border-primary-500 bg-primary-50' : 'border-gray-200 hover:border-primary-200'
                    }`}
                  >
                    <div className="h-12 w-12 mb-4 text-primary-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                      </svg>
                    </div>
                    <h3 className="text-lg font-medium mb-2">Social Media Post</h3>
                    <p className="text-sm text-gray-600">Create engaging social media content optimized for your platform.</p>
                  </div>

                  <div
                    onClick={() => setContentType('product review')}
                    className={`bg-white p-6 rounded-xl border cursor-pointer transition-all ${
                      contentType === 'product review' ? 'border-primary-500 bg-primary-50' : 'border-gray-200 hover:border-primary-200'
                    }`}
                  >
                    <div className="h-12 w-12 mb-4 text-primary-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z" />
                      </svg>
                    </div>
                    <h3 className="text-lg font-medium mb-2">Product Review</h3>
                    <p className="text-sm text-gray-600">Detailed product analysis with pros, cons, and comparisons.</p>
                  </div>

                  <div
                    onClick={() => setContentType('short ecommerce product description')}
                    className={`bg-white p-6 rounded-xl border cursor-pointer transition-all ${
                      contentType === 'short ecommerce product description' ? 'border-primary-500 bg-primary-50' : 'border-gray-200 hover:border-primary-200'
                    }`}
                  >
                    <div className="h-12 w-12 mb-4 text-primary-500">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                      </svg>
                    </div>
                    <h3 className="text-lg font-medium mb-2">Product Description</h3>
                    <p className="text-sm text-gray-600">Concise, compelling product descriptions for e-commerce.</p>
                  </div>
                </div>
              </div>

              {/* Article Context */}
              <div className="mb-12">
                <h2 className="text-2xl font-medium mt-6 mb-1">Article Context</h2>
                <p className="text-base text-gray-500 mb-4">
                  Provide a general description of what you want to write about or paste your custom knowledge base content here.
                </p>
                <textarea
                  value={articleContext}
                  onChange={(e) => setArticleContext(e.target.value)}
                  placeholder="What would you like to write about?"
                  className="w-full min-h-[200px] rounded-lg border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                  rows="6"
                />
              </div>
            </section>

            {/* Audience Setup Section */}
            <section id="audience-setup" className="mb-16">
              <div className="flex items-center justify-between mb-8">
                <div>
                  <h2 className="text-2xl font-medium mt-6 mb-1">Advanced Setup</h2>
                  <p className="text-base text-gray-500 mb-4">
                    Provide a general description of what you want to write about or paste your custom knowledge base content here.
                  </p>
                </div>
                <Switch
                  checked={showAdvancedSettings}
                  onChange={setShowAdvancedSettings}
                  className={`${showAdvancedSettings ? 'bg-primary-600' : 'bg-gray-200'
                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Enable advanced settings</span>
                  <span
                    className={`${showAdvancedSettings ? 'translate-x-6' : 'translate-x-1'
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                  />
                </Switch>
              </div>

              {showAdvancedSettings && (
                <div className="space-y-6">
                  {/* Tone of Voice */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Tone of Voice</label>
                    <select
                      value={toneOfVoice}
                      onChange={(e) => setToneOfVoice(e.target.value)}
                      className="w-full rounded-lg border-gray-300"
                    >
                      <option value="friendly">Friendly</option>
                      <option value="professional">Professional</option>
                      <option value="casual">Casual</option>
                      <option value="formal">Formal</option>
                      <option value="authoritative">Authoritative</option>
                    </select>
                  </div>

                  {/* Complexity */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Complexity</label>
                    <select
                      value={complexity}
                      onChange={(e) => setComplexity(e.target.value)}
                      className="w-full rounded-lg border-gray-300"
                    >
                      <option value="fog index: 5">Very simple</option>
                      <option value="fog index: 10">Simple</option>
                      <option value="fog index: 15">Moderate</option>
                      <option value="fog index: 20">Complex</option>
                      <option value="fog index: 25">Professional</option>
                    </select>
                  </div>

                  {/* Language */}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Language</label>
                    <select
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                      className="w-full rounded-lg border-gray-300"
                    >
                      <option value="polski">Polish</option>
                      <option value="english">English</option>
                      <option value="german">German</option>
                    </select>
                  </div>
                </div>
              )}
            </section>

            {/* SEO Keywords Section */}
            <section id="seo-keywords" className="mb-16">
              <h2 className="text-2xl font-medium mb-1">SEO Keywords</h2>
              <p className="text-base text-gray-500 mb-4 ">
                Select from suggested keywords or add your own to optimize your content.
              </p>

              <div className="space-y-4">
                {/* Manual keyword input */}
                <div className="flex gap-4">
                  <input
                    type="text"
                    value={keywordInput}
                    onChange={(e) => setKeywordInput(e.target.value)}
                    placeholder="Add keywords..."
                    className="flex-1 rounded-lg border-gray-300"
                  />
                  <button
                    onClick={handleAddKeyword}
                    className="px-4 py-2 max-h-14 bg-primary-500 text-white rounded-lg hover:bg-primary-600"
                  >
                    Add
                  </button>
                </div>

                {/* Selected keywords */}
                {keywords.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Selected Keywords</h3>
                    <KeywordsTable
                      keywords={keywords}
                      selectAllKeywords={selectAllKeywords}
                      handleSelectAll={handleSelectAll}
                      handleRemoveKeyword={handleRemoveKeyword}
                    />
                  </div>
                )}

                {/* Add the new SuggestedKeywords component */}
                <SuggestedKeywords 
                  keywords={keywords}
                  onKeywordSelect={(keyword) => setKeywords([...keywords, keyword])}
                />
              </div>
            </section>

            {/* Sources Section */}
            <SourcesSection 
              articleContext={articleContext}
              keywords={keywords}
            />

            {/* Action Buttons - Fixed Bottom Bar */}
            <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 px-4 py-4 z-50">
              <div className="max-w-7xl mx-auto flex justify-end space-x-4">
                <button
                  type="submit"
                  disabled={loading}
                  onClick={handleSubmit}
                  className={`inline-flex items-center justify-center rounded-full px-6 py-3 text-base shadow-sm focus:outline-none focus:ring-2 ${
                    !loading
                      ? 'bg-primary-500 hover:bg-primary-600 cursor-pointer focus:ring-primary-500 focus:ring-offset-2 text-white'
                      : 'bg-gray-300 cursor-not-allowed text-gray-500'
                  }`}
                >
                  {loading ? 'Generating...' : (contentType === 'short ecommerce product description' || contentType === 'social media post') ? 'Create' : 'Create Draft'}
                </button>

                {contentType !== 'short ecommerce product description' && contentType !== 'social media post' && (
                  <button
                    onClick={handleContinue}
                    disabled={!editorHasContent || loading}
                    className={`inline-flex items-center justify-center rounded-full px-6 py-3 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                      editorHasContent && !loading
                        ? 'bg-primary-500 hover:bg-primary-600 focus:ring-primary-500'
                        : 'bg-gray-300 cursor-not-allowed'
                    }`}
                  >
                    Generate
                  </button>
                )}
              </div>
            </div>

            {/* Add padding to the bottom of the main content to prevent content from being hidden behind the fixed bar */}
            <h2 className="text-2xl font-medium mb-8">Draft Review</h2>
            <div className="pb-24 bg-white py-8 px-16 shadow-lg rounded-lg">
              {/* Draft Review Section */}
              <section id="draft-review" className={`mb-16 ${isDraftGenerated ? '' : 'hidden'}`}>

                <div id="editorjs" className={editorHasContent ? '' : 'hidden'}></div>
                {isAutosaving && <p className="text-sm text-gray-500 mt-2">Autosaving...</p>}
              </section>
            </div>
          </div>

          {/* Vertical Stepper */}
          <div className="w-64 flex-shrink-0">
            <nav aria-label="Progress" className="sticky top-8">
              <ol role="list" className="space-y-6">
                {steps.map((step) => (
                  <li key={step.name}>
                    <button
                      onClick={() => handleStepClick(step.href)}
                      className="w-full text-left"
                    >
                      {step.status === 'complete' ? (
                        <div className="group">
                          <span className="flex items-start">
                            <span className="relative flex h-5 w-5 shrink-0 items-center justify-center">
                              <CheckCircleIcon className="h-full w-full text-primary-600 group-hover:text-primary-800" />
                            </span>
                            <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                              {step.name}
                            </span>
                          </span>
                        </div>
                      ) : step.status === 'current' ? (
                        <div className="flex items-start" aria-current="step">
                          <span className="relative flex h-5 w-5 shrink-0 items-center justify-center">
                            <span className="absolute h-4 w-4 rounded-full bg-primary-200" />
                            <span className="relative block h-2 w-2 rounded-full bg-primary-600" />
                          </span>
                          <span className="ml-3 text-sm font-medium text-primary-600">{step.name}</span>
                        </div>
                      ) : (
                        <div className="group">
                          <div className="flex items-start">
                            <div className="relative flex h-5 w-5 shrink-0 items-center justify-center">
                              <div className="h-2 w-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                            </div>
                            <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                              {step.name}
                            </p>
                          </div>
                        </div>
                      )}
                    </button>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}